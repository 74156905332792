<template>
    <div class="home">
      <el-menu :default-active="formInline.status" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="">全部</el-menu-item>
        <el-menu-item index="1">启用</el-menu-item>
        <el-menu-item index="2">禁用</el-menu-item>
      </el-menu>
<div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
<el-form-item label="生成时间">
                <el-date-picker
                    v-model="timevalue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>

            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.query" style="width:300px" placeholder="1级分类名称、分类编号"></el-input>
            </el-form-item>
            <el-form-item>
            <el-button type="primary"  style="margin-left:10px" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 
<div class="pt20"></div>
      <div class="left">
        <el-button v-if="add == 1" @click="goNav('/label/topicadd')" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加</el-button>
        <el-button v-if="dele == 1" @click="menuChangeStatus(3)" type="danger" style="font-size: 16px;font-weight: bold;" plain>删除</el-button>
        <el-button v-if="dele == 1" @click="menuChangeStatus(1)" type="success" style="font-size: 16px;font-weight: bold;" plain>启用</el-button>
        <el-button v-if="dele == 1" @click="menuChangeStatus(2)" type="warning" style="font-size: 16px;font-weight: bold;" plain>禁用</el-button>
      </div>
      <el-table
      v-loading="loading"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange" >
       <el-table-column type="selection" width="55" align="center"/>
          <el-table-column label="序号" :index="typeIndex" type="index" show-overflow-tooltip width="50">
        </el-table-column>
        <el-table-column
          prop="id"
          label="分类编号">
        </el-table-column>
        <el-table-column
          prop="name"
          label="一级分类名称">
        </el-table-column>
        
        <el-table-column
          prop="second_count" 
          label="二级分类数">
        </el-table-column>

        
        <el-table-column
          prop="topic_count" 
          label="使用量">
        </el-table-column>

        <el-table-column
          prop="created_at" 
          label="生成时间">
        </el-table-column>
        <el-table-column
          prop="status" 
          label="状态">
          <template slot-scope="scope">
              {{scope.row.status==1?'启用':''}}{{scope.row.status==2?'禁用':''}}{{scope.row.status==3?'删除':''}}
          </template>
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
        <el-button v-if="edit == 1" @click="goedit(scope.row)" size="mini" type="info" plain>编辑</el-button>
        <el-button v-if="detail == 1" @click="goedit2(scope.row)" size="mini" type="info" plain>查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        
      
<el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      add:location.add,
      dele:location.delete,
      edit:location.edit,
      detail:location.detail,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      formInline: {
         sdate:'',
         edate:'',
         query:'',
         page:1,
         status:'',
      },
      timevalue:'',
      timevalue2:'',
      // 选中数组
      ghs: [],
      labelcount:[],//选中的使用量
      labelname:[],//选中的名字
     //选中的记录数量
     initdata:{
         sdate:'',
         edate:'',
         query:'',
         page:1,
         status:'',
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  methods: {
     handleSelect(key) {
        this.formInline.status = key;
        this.onSubmit('CHA');
      },
      goNav(url){
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
        this.$router.push(url);
      },
      onSubmit(CHA){
        let _this = this;
         _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
          _this.formInline.edate = '';
          _this.formInline.edate = '';
          if(_this.timevalue){
            _this.formInline.sdate	 = formatDate(_this.timevalue[0]);
            _this.formInline.edate = formatDate(_this.timevalue[1]);
          }
        axios.get(config.topicCategory,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },typeIndex(index) {
      let _this = this; //处理分页数据的 index
      return (_this.formInline.page - 1) * 20 + index + 1;
    },
      goedit(obj){
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
       // this.$router.push('/label/topicedit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/label/topicedit');
      },
      goedit2(obj){
        obj.look = true;
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
      //  this.$router.push('/label/topicedit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/label/topicedit');
      },
    setpage(page){
      this.total = page.total;
      this.per_page = page.per_page;
      this.current_page = page.current_page;
    },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },

    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.id)
        this.labelcount = selection.map(item => item.topic_count);
        this.labelname = selection.map(item => item.name);
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
        console.log(this.ghs);
        console.log(this.selectedNum);
      },menuChangeStatus(status){
          let _this = this;
        if(this.selectedNum == 0){
          this.$message.error('请先勾选');
          return;
        }
        for(let i in _this.labelcount){
          if(_this.labelcount[i] > 0){
            this.$message.error(_this.labelname[i] + '正在使用中');
            return;
          }
        }
        this.$confirm('确认执行操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post(config.topicCategoryStatus, {id:this.ghs,status:status})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.onSubmit();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });

      },
    init(){
        let _this = this;
        let a = sessionStorage.getItem('formInline');
        this.formInline = a?JSON.parse(a):this.initdata;
        axios.get(config.topicCategory,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
      
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
    text-align: left;
}
</style>